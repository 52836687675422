export default function GoogleMap({ width = 600, height = 450 }) {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.482898792901!2d114.17162299043656!3d22.297569690633512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ee32ec43d1%3A0x4b8ffd1fbdc80682!2z5bCW5p2x6bq85Zyw6YGTNjHomZ_lhqDoj6_kuK3lv4M!5e0!3m2!1szh-TW!2shk!4v1711035689414!5m2!1szh-TW!2shk"
      width={width}
      height={height}
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}
