import Main from "../components/rows/Main";
import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
// import Separator from "../components/rows/Separator";
// import ListOl from "../components/rows/ListOl";
// import ListUl from "../components/rows/ListUl";
import Img from "../components/rows/Img";

import ServiceRow from "./pagerows/ServiceRow";

export default function Services() {
  const coltext = "col-md-6 p-5";
  return (
    <div className="service">
      <section className="p-3">
        <Section className="container">
          {/* <Row className="justify-content-between align-items-center mb-5 p-5"> */}
          <Row className="justify-content-between align-items-center">
            <h1>我們的服務</h1>
          </Row>
        </Section>
      </section>
      <Main className="">
        <Section className="container">
          <ServiceRow
            className="border-top bd-secondary flex-row-reverse"
            imgSrc="/img/service/service1.jpeg"
            imgAlt="網站製作"
            imgAriaHidden={true}
          >
            <h3>網站製作</h3>
            <p>我們專注於為各行各業的客戶提供量身訂製的網頁設計與開發服務</p>
            <p>
              從小型企業到大型企業，我們為每位客戶帶來量身打造、獨一無二的網上解決方案
            </p>
            <p>
              我們的網站製作服務包括使用流行的內容管理系統(CMS)，例如WordPress、Joomla、Drupal和Magento
            </p>
          </ServiceRow>

          <ServiceRow
            className="bg-black text-white"
            imgSrc="/img/service/service2.jpeg"
            imgAlt="網上商店製作"
            imgAriaHidden={true}
          >
            <h3>網上商店製作</h3>
            <p>
              在當今快節奏的數碼世界中，一個高效、吸引人且易於導航的網上商店是企業成功的關鍵。我們的團隊專注於提供專業的網上商店製作服務，幫助您的業務在網絡世界中蓬勃發展。憑藉我們深厚的技術知識和豐富的經驗，我們能夠創建既美觀又高效的電商平台，完美地展現您的產品和服務
            </p>
            <p>
              我們理解每個業務都有其獨特性，因此我們提供定制化的解決方案，以滿足您特定的需求。從用戶界面設計到後端系統整合，從支付閘道到客戶關係管理，我們確保每一個環節都能無縫對接，提供給您和您的客戶無與倫比的購物體驗。此外，我們的網站都是響應式設計，確保在各種設備上都能完美運行，從而擴大您的目標客戶群
            </p>
            <p>
              選擇我們的網上商店製作服務，意味著選擇一個可靠的合作夥伴，我們致力於您的業務成長和繁榮。讓我們攜手共創您的數碼商業奇跡！
            </p>
            {/* <p>
                我們的網上商店製作服務包括使用流行的電子商務平台，例如Shopify、WooCommerce、Magento和OpenCart
              </p> */}
          </ServiceRow>

          <ServiceRow
            className="flex-row-reverse"
            imgSrc="/img/service/service3.jpeg"
            imgAlt="無障礙網站服務"
            imgAriaHidden={true}
          >
            <h3>無障礙網站服務</h3>
            <p>
              無障礙網站是指能夠讓所有人都能夠瀏覽和使用的網站。這包括老年人、殘疾人、盲人、色盲人士、聽障人士、語言障礙人士、低識字率人士等。無障礙網站能夠讓這些人士輕鬆瀏覽您的網站，從而擴大您的目標客戶群
            </p>
            <p>
              而且，無障礙網站也能夠提高您的網站的搜索引擎排名，從而為您的業務帶來更多的流量
            </p>
            <p>
              我們的網站都是無障礙設計，確保在各種設備上都能完美運行，從而擴大您的目標客戶群
            </p>
          </ServiceRow>

          <ServiceRow
            className="bg-black text-white"
            imgSrc="/img/service/service4.jpeg"
            imgAlt="內容管理系統 (CMS)"
            imgAriaHidden={true}
          >
            <h3>內容管理系統 (CMS)</h3>
            <p>
              我們有多年的網站製作經驗，而且對利用流行的內容管理系統(CMS)
              製作網站有相當多的經驗
            </p>
            <p>
              CMS是適合客戶沒有程序員的小型公司，可以輕鬆修改和發佈新的內容在網站上
            </p>
            <p>
              我們的網站製作服務包括使用流行的CMS，例如WordPress、Joomla、Drupal和Magento
            </p>
          </ServiceRow>

          <ServiceRow
            className="flex-row-reverse"
            imgSrc="/img/service/service5.jpeg"
            imgAlt="響應式網站設計(RWD)"
            imgAriaHidden={true}
          >
            <h3>響應式網站設計(RWD)</h3>
            <p>
              響應式網站設計(RWD)是一種網站設計方法，能夠確保網站在不同設備上（包括手機、平板和桌面）都有良好的使用體驗
            </p>
            <p>
              我們的網站都是響應式設計，確保在各種設備上都能完美運行，從而擴大您的目標客戶群
            </p>
            <p>
              我們使用最新的前端技術，如HTML5、CSS3和JavaScript框架，確保您的網站在各種設備上都能完美運行
            </p>
          </ServiceRow>

          {/* 基於 WordPress 的網站設計服務 網站製作服務 加強 網站設計服務 */}
        </Section>
      </Main>
    </div>
  );
}
