import "./Separator.scss";
export default function Separator({ children, className = "", style = {} }) {
  return (
    <div
      className={`separator fs-3 fw-bold pt-5 pb-5 ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}
