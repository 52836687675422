export default function Card({
  children,
  title,
  text,
  imgsrc = "/img/Logo---square-400x400-ol.png",
  imgalt = "Card image",
}) {
  return (
    <div className="card">
      {/* <div className="card-header">Featured</div> */}
      {/* <img src="/img/Logo---square-400x400-ol.png" /> */}
      <img className="p-5" src={imgsrc} alt={imgalt} aria-hidden />
      {/* <div className={imgclass}></div> */}

      <div className="card-body">
        <h3 className="card-title">{title}</h3>
        <p className="card-text">{text}</p>
        {children}
      </div>
      {/* <div className="card-footer text-muted">2 days ago</div> */}
    </div>
  );
}
