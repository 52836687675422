import Main from "../components/rows/Main";
import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
import Separator from "../components/rows/Separator";

import BGvideo from "../video/background_line.mov";
import Card from "../components/rows/Card";

import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="home">
      <section className="cover bg-image vh-100 shadow-1-strong">
        <video
          autoPlay
          loop
          muted
          style={{ minWidth: "100%", minHeight: "100%" }}
        >
          <source className="h-100" src={BGvideo} type="video/mp4" />
        </video>
        <div
          className="mask"
          style={{
            background:
              "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)",
          }}
        >
          {/* 向人們展示你的東西比起ppt 不如做一個網站 */}
          <div className="container d-flex align-items-center justify-content-center text-center h-100">
            <div className="text-white" data-mdb-theme="dark">
              <h1 className="mb-3">你需要一個表達你的網站</h1>
              <h2 className="mb-4">Best of web design</h2>
              <a
                className="btn btn-outline-light btn-lg m-2"
                data-mdb-ripple-init
                href="/website"
                role="button"
                rel="nofollow"
                // target="_blank"
              >
                服務介紹
              </a>
              <a
                className="btn btn-outline-light btn-lg m-2"
                // data-mdb-ripple-init
                href="mailto:info@anktech.io"
                // href="/contact-us"
                // target="_blank"
                // role="button"
              >
                獲取報價
              </a>
            </div>
          </div>
        </div>
      </section>

      <Main className="container mt-5">
        <Section>
          <Row className="justify-content-between align-items-center">
            <Col className="col-12 col-md-6 mb-5 mt-5">
              <img
                src="/img/AnK_Logo.png"
                alt="A&K technologies Logo"
                style={{ width: "100%" }}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <h2>為什麼你需要一個網頁</h2>
              <p>
                透過網頁，你可以把你的產品、服務、理念、或是任何想法
                <br />
                透過網頁，把它們傳達給更多人，讓更多人認識你，認識你的產品、服務、理念、或是任何想法
              </p>
              <p>
                我們的網頁設計團隊，會為你的網頁設計一個獨特的風格，讓你的網頁與眾不同
              </p>
            </Col>
          </Row>
        </Section>

        <Separator />

        <Section className="text-center">
          <h2 className="mb-5"></h2>
          <Row className="justify-content-between align-items-center">
            <Col className="col-12 col-md-4">
              <Card
                title="網頁製作服務"
                // text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam necessitatibus incidunt ut officiis explicabo inventore."
                imgsrc="/img/iconset5x/home1.png"
              >
                <a href="/website" className="btn btn-primary">
                  了解更多
                </a>
              </Card>
            </Col>
            <Col className="col-12 col-md-4">
              <Card
                title="其他服務"
                // text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam necessitatibus incidunt ut officiis explicabo inventore."
                imgsrc="/img/iconset5x/home2.png"
              >
                <a href="/services" className="btn btn-primary">
                  了解更多
                </a>
              </Card>
            </Col>
            <Col className="col-12 col-md-4">
              <Card
                title="獲取報價"
                // text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam necessitatibus incidunt ut officiis explicabo inventore."
                imgsrc="/img/iconset5x/home3.png"
              >
                <a href="/contact-us" className="btn btn-primary">
                  了解更多
                </a>
              </Card>
            </Col>
          </Row>
        </Section>
      </Main>
    </div>
  );
}
