export default function Img({
  className = "",
  style = {},
  imgsrc = "/img/Logo---square-600x600-ol.png",
  imgalt = "icon image",
  ariaHidden = false,
}) {
  return (
    <img
      className={`p-5 ${className}`}
      style={style}
      src={imgsrc}
      alt={imgalt}
      aria-hidden={ariaHidden}
    />
  );
}
