import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import Img from "../../components/rows/Img";
export default function ServiceRow({
  children,
  className,
  imageClassName,
  imgStyle,
  imgSrc,
  imgAlt,
  imgAriaHidden,
}) {
  return (
    <Row className={`justify-content-between align-items-center ${className}`}>
      <Col className="col-12 col-md-6 bg-secondary-subtle">
        <Img
          className={imageClassName}
          style={imgStyle}
          imgsrc={imgSrc}
          imgalt={imgAlt}
          ariaHidden={imgAriaHidden}
        />
      </Col>
      <Col className="col-md-6 p-5">{children}</Col>
    </Row>
  );
}
