import { useOutlet, useNavigate, Navigate } from "react-router-dom";
import { AppBar } from "./AppBar";
// import { Sidebar } from "./Sidebar";
import Header from "../rows/Header";
import Main from "../rows/Main";
import Footer from "../rows/Footer";
import Section from "../rows/Section";
import Row from "../rows/Row";
import Col from "../rows/Col";
import GoogleMap from "../rows/GoogleMap";

export default function MainLayout() {
  const outlet = useOutlet();

  return (
    <>
      <Header className="container pt-2 pb-2">
        <img
          className="Logo d-none d-lg-block"
          src="/img/AnK_Logo.png"
          alt="A&K technologies Logo"
        />
      </Header>
      <AppBar
        pages={[
          { label: "主頁", path: "/" },

          { label: "關於我們", path: "/about-us" },

          // Main Business
          { label: "網站製作", path: "/website" },
          // { label: "AM Elephant", path: "/am-elephant" },
          { label: "我們的服務", path: "/services" },

          { label: "聯絡我們", path: "/contact-us" },
        ]}
      />
      <Main>{outlet}</Main>
      <Footer className="container-fluid border-top">
        <section className="p-3 container">
          <Section>
            <Row>
              <Col className="col-12 col-md-4">
                地址: <br />
                Unit 17A, L2 Floor, Mirror Tower, No.61 Mody Road, Tsim Sha Tsui
                East, Kowloon, Hong Kong
              </Col>
              <Col className="col-12 col-md-4">
                {/* <h3>聯絡我們</h3> */}
                <p>
                  電郵: <a href="mailto:info@anktech.io">A&K Info</a>
                </p>
                <p>
                  電話: <a href="tel:+85291247691">+852 9124 7691</a>
                </p>
                <p>
                  {/* <i class="fa-brands fa-facebook"></i> */}
                  <a
                    // className="btn btn-outline-light btn-lg m-2"
                    href="https://www.facebook.com/people/AK-Technologies/61558603801589/"
                    target="_blank"
                  >
                    <i class="fa-brands fa-square-facebook fa-3x"></i>
                  </a>{" "}
                  <a
                    // className="btn btn-outline-light btn-lg m-2"
                    href="https://www.linkedin.com/company/a-and-k-technologies/"
                    target="_blank"
                  >
                    <i class="fa-brands fa-linkedin fa-3x"></i>
                  </a>
                </p>
              </Col>
              <Col className="col-12 col-md-4">
                <div className="googlemap">
                  <GoogleMap width={400} height={250} />
                </div>
              </Col>
            </Row>
          </Section>
          <Section>
            <Row className="justify-content-center">
              <Col className="col-md-4 text-center">
                <p>© 2023 A&K Technologies Limited</p>
              </Col>
            </Row>
          </Section>
        </section>
      </Footer>
    </>
  );
}
