import Main from "../components/rows/Main";
import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
import Separator from "../components/rows/Separator";
import GoogleMap from "../components/rows/GoogleMap";

export default function ContactUs() {
  return (
    <div className="contact-us">
      <section className="p-3">
        <Section className="container">
          <Row className="justify-content-between align-items-center">
            <h1>聯絡我們</h1>
          </Row>
        </Section>
      </section>

      <Main className="">
        {/* <Separator>
          <h2>價格</h2>
        </Separator> */}

        <Section className="container">
          <Row className="justify-content-between align-items-center">
            <Col className="col-12 col-md-6 p-5">
              <p className="h4">電郵</p>
              <p>
                <a href="mailto:info@anktech.io">info@anktech.io</a>
              </p>
              <p className="h4">電話</p>
              <p>
                <a href="tel:+85291247691">+852 9124 7691</a>
              </p>

              <p className="h4">地址</p>
              <p>
                Unit 17A, L2 Floor, Mirror Tower, No.61 Mody Road, Tsim Sha Tsui
                East, Kowloon, Hong Kong
              </p>
            </Col>
            <Col className="col-12 col-md-6 p-3">
              {/* <img src="/img/Logo---square-600x600-ol.png" /> */}
              <div className="googlemap">
                <GoogleMap />
              </div>
            </Col>
          </Row>
          <Separator />
        </Section>
      </Main>
    </div>
  );
}
