export default function Col({ children, className = "", style = {} }) {
  return (
    <div className={`col ${className}`} style={style}>
      {children}
    </div>
  );
}

// const Col = ({ children, size = "12", mdSize = "6", textAlign = "start", padding = "5" }) => (
//   <div className={`col-${size} col-md-${mdSize} text-${textAlign} p-${padding}`}>
//     {children}
//   </div>
// );
