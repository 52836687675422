import MainLayout from "./components/base/MainLayout";
import { Navigate } from "react-router-dom";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";

import Website from "./pages/Website";
import AMElephant from "./pages/AM-Elephant";
import Services from "./pages/Services";

import "./sass/custom.scss";

function App() {
  const router = [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        // { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "/", element: <Home /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/contact-us", element: <ContactUs /> },
        { path: "/website", element: <Website /> },
        { path: "/am-elephant", element: <AMElephant /> },
        { path: "/services", element: <Services /> },
        // { path: "*", element: <Navigate to="/404" /> }
      ],
    },
  ];
  return router;
}

export default App;
