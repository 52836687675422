import Main from "../components/rows/Main";
import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
// import Separator from "../components/rows/Separator";

export default function AboutUs() {
  return (
    <div className="about-us">
      <Main className="container mt-5">
        <h1>AboutUs</h1>
        <Section>
          <Row className="justify-content-between align-items-center">
            <Col className="col-12 col-md-6 mb-5 mt-5">
              <img
                src="/img/AnK_Logo.png"
                alt="A&K technologies Logo"
                style={{ width: "100%" }}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <h2>關於我們</h2>
              <p>
                透過網頁，你可以把你的產品、服務、理念、或是任何想法
                <br />
                透過網頁，把它們傳達給更多人
              </p>
              <p>歡迎來到 A&K Technologies Limited！</p>
              <p>
                {/* 我們的網頁設計團隊，會為你的網頁設計一個獨特的風格，讓你的網頁與眾不同。 */}
                自2022年創立以來，我們一直致力於提供前沿的網頁設計和開發服務，將創新思維與專業技術完美結合。作為一家充滿活力的IT公司，我們專注於創造既引人入勝又富有功能的數字解決方案
              </p>
              <p>
                我們的團隊由一群充滿激情的設計師和技術專家組成，他們對於每一個項目都全情投入，確保我們的客戶能夠享受到既創新又實用的網站體驗。無論是企業網站、電子商務平台，還是定制的網絡應用，我們都致力於為您提供超越期待的服務
              </p>
              <p>
                在 A&K
                Tech，我們深知每一位客戶和他們的需求都是獨一無二的。因此，我們提供量身定做的解決方案，不僅滿足您當前的需求，更能夠支持您的業務未來發展。我們的目標不僅是成為您的服務提供商，更是成為您值得信賴的長期合作夥伴
              </p>
              <p>
                選擇 A&K
                Tech，意味著選擇一個專業而有活力的團隊，我們將與您一起，共創您的數字未來
              </p>
            </Col>
          </Row>
        </Section>
      </Main>
    </div>
  );
}
