import { NavLink } from "react-router-dom";
// import "./AppBar.css";

export const AppBar = ({ pages }) => {
  let activeStyle = {
    textDecoration: "underline",
  };

  let activeClassName = "underline";

  return (
    <nav className="navbar navbar-dark navbar-expand-lg">
      {/* <nav className="navbar navbar-expand-lg bg-body-tertiary"> */}
      {/* <div className="container-fluid"> */}
      <div className="container">
        {/* <a className="navbar-brand" href="#">
          Navbar
        </a> */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {pages?.map((page) => (
              <li className="nav-item" key={page.label}>
                <NavLink
                  className="nav-link"
                  to={page.path}
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  {page.label}
                </NavLink>
              </li>
            ))}
          </ul>
          {/* <form className="d-flex" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
        </div>
      </div>
    </nav>
  );
};
