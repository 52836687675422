import Main from "../components/rows/Main";
import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
import Separator from "../components/rows/Separator";
// import ListOl from "../components/rows/ListOl";
// import ListUl from "../components/rows/ListUl";
import Img from "../components/rows/Img";

import WebsiteRow from "./pagerows/WebsiteRow";

export default function Website() {
  return (
    <div className="website">
      <section className="p-3 promise">
        {/* <h1 className="text-center">網站製作服務</h1> */}

        <Section className="container">
          <Row className="justify-content-between align-items-center mb-5 p-5">
            <h1>網站製作服務</h1>
            <Col className="col-md-12 pt-5 mt-4 mb-5 text-white">
              <p className="fs-4">您的一站式數位解決專家</p>
              <p className="border border-end-0 border-bottom-0 pt-3 pb-5 ps-3 fs-4">
                我們專注於為各行各業的客戶提供量身訂製的網頁設計與開發服務
                <br />
                從小型企業到大型企業，我們為每位客戶帶來量身打造、獨一無二的網上解決方案
              </p>
            </Col>
          </Row>
        </Section>
      </section>

      <Main className="container mt-5">
        <Separator>
          <h2>服務特點</h2>
        </Separator>

        <Section>
          <WebsiteRow
            className="flex-row-reverse"
            imgSrc="/img/iconset5x/web1.png"
            imgAriaHidden={true}
          >
            <h3>客製化網站設計</h3>
            專業設計團隊根據您的品牌風格和市場定位，打造符合企業形象的網站
            <br />
            提供互動式設計元素，增加用戶參與度和體驗感
          </WebsiteRow>

          <Separator />

          <WebsiteRow imgSrc="/img/iconset5x/web2.png" imgAriaHidden={true}>
            <h3>響應式網站開發 (RWD)</h3>
            確保網站在不同設備上（包括手機、平板和桌面）都有良好的使用體驗
            <br />
            使用最新的前端技術，如HTML5、CSS3和JavaScript框架
          </WebsiteRow>

          <Separator />

          <WebsiteRow
            className="flex-row-reverse"
            imgSrc="/img/iconset5x/web3.png"
            imgAriaHidden={true}
          >
            <h3>內容管理系統 (CMS)</h3>
            提供易於使用的 CMS，讓客戶輕鬆更新網站內容
            <br />
            支援多語言內容管理，適應全球化市場需求
          </WebsiteRow>

          <Separator />

          <WebsiteRow imgSrc="/img/iconset5x/web4.png" imgAriaHidden={true}>
            <h3>搜尋引擎最佳化 (SEO)</h3>
            優化網站結構和內容，提高在Google等搜尋引擎的排名
            <br />
            定期分析網站流量和用戶行為，不斷優化SEO策略
          </WebsiteRow>
        </Section>
      </Main>

      <section className="p-3 promise">
        {/* <section id="intro" class="cover bg-image vh-100 shadow-1-strong"> */}
        <Separator>
          <h2>我們的承諾</h2>
        </Separator>

        <Row className="justify-content-center align-items-center mb-5">
          <Col className="col-12 col-md-6 text-center">
            {/* <div className="d-flex justify-content-center"> */}
            <div className="border rounded-3 p-5">
              {/* <div className="shadow p-3 mb-5 rounded"> */}
              <h3>客戶至上</h3>
              <p>
                我們透過深入的市場分析和用戶研究，提供完全符合您需求的解決方案
              </p>
              <h3>質量保證</h3>
              <p>堅持使用最新技術和創新方法，確保網站設計和開發的最高質量</p>
              <h3>持續支援</h3>
              <p>提供專業的售後服務，包括網站維護、技術支援和更新服務</p>
            </div>
          </Col>
        </Row>
      </section>
      {/* </section> */}

      {/* <Separator>
          <h2>我們的案例展示</h2>
        </Separator>
        <Section>
          <Row className="justify-content-between align-items-center">
            <Col className="col-md-12">
              <p>
                在這裡，我們展示了我們與不同行業客戶合作的成功案例。從時尚零售到高科技企業，我們的專業團隊已經為各種業務打造了獨特而有效的網絡解決方案。
              </p>
            </Col>
          </Row>
        </Section> */}
    </div>
  );
}
